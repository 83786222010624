<template>
  <div v-if="callBackObj" class="report-list-wrapper">
    <div class="banner">
      <img src="@/assets/image/banner2.png" />
    </div>
    <div class="center-box">
      <ul v-if="callBackObj.data.length">
        <li
          v-for="(item, index) in callBackObj.data"
          @click="onShowReportFn(item)"
          :key="index"
        >
          <div class="top-box">
            <div class="left">
              <img :src="item.user_info.avatar" />
              <span>{{ item.user_info.nickname }}</span>
            </div>
            <div class="right">
              <span>查看报告</span>
              <img src="@/assets/image/more-icon.png" />
            </div>
          </div>
          <div class="bottom-box">
            <div class="left">
              <div class="title">{{ item.info.title }}</div>
              <div class="date">{{ item.create_time }}</div>
            </div>
            <div class="right">
              <img :src="item.info.cover" />
              <div class="right-fixed">
                <div v-if="item.is_counting === 1" class="num">评分中...</div>
                <div v-if="item.is_counting === 0" class="num">
                  {{ item.total_score }}
                </div>
                <span v-if="item.is_counting === 0">分</span>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div v-if="callBackObj.data.length === 0" class="no-data">
        暂无面试记录
      </div>
      <div
        v-if="
          callBackObj.data.length &&
          channel !== '1586f7b9af79cf6371b467dcdcbfdd99'
        "
        class="empty-btn"
        @click="onEmptyDataFn"
      >
        <img src="@/assets/image/empty-icon.png" />
      </div>
    </div>
    <!--分页码-->
    <div class="pagination-container">
      <el-pagination
        v-if="callBackObj.data.length"
        :current-page="callBackObj.current_page"
        :total="callBackObj.total"
        :page-size="12"
        background
        @current-change="handleCurrentChange"
      />
    </div>
    <!--报告弹窗-->
    <el-dialog
      :visible.sync="centerDialogVisible"
      width="400px"
      top="5vh"
      center
    >
      <iframe :src="report_url" width="100%" height="750px" frameborder="no" />
    </el-dialog>
    <!--返回按钮-->
    <div @click="onSkipPageFn('/interview/welcome')" class="return-btn">
      <img src="@/assets/image/return-icon.png" />
    </div>

    <ExitApplication v-if="popUp" @ensureFn="ensureFn" @cancelfn="cancelfn" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { platform, H5Url } from '@/api/config'
import ExitApplication from '@/components/ExitApplication.vue'

let timer = null

export default {
  components: {
    ExitApplication
  },
  data() {
    return {
      H5Url,
      platform,
      popUp: false, // 控制退出应用的弹窗
      channel: '',
      callBackObj: null,
      report_url: false, // 报告url
      centerDialogVisible: false, // 显示报告状态
      submitObj: {
        channel: null,
        page_size: 12,
        page: 1
      }
    }
  },
  created() {
    const _this = this
    if (this.$route.query.channel) this.channel = this.$route.query.channel
    this.getReportListFn()
    document.onkeydown = function (e) {
      if (e.keyCode === 27) _this.onOutPageFn()
    }
  },
  methods: {
    ...mapActions(['getReportList', 'emptyReportList']),
    // 获取面试列表
    async getReportListFn() {
      clearInterval(timer)
      this.submitObj.channel = this.channel
      this.callBackObj = await this.getReportList(this.submitObj)
      timer = setInterval(() => {
        this.getReportListFn()
      }, 2000)
    },
    // 显示隐藏报告
    onShowReportFn(item) {
      if (this.platform === 'msyz') this.report_url = `${this.H5Url}pages/aiInterviewData/aiInterviewReport/index?aiypid=${item.id}`
      if (this.platform === 'digitalEmployment') this.report_url = `${this.H5Url}pages/aiInterviewSystem/aiInterviewRecordList/reportDetails/index/?report_id=${item.id}`
      this.centerDialogVisible = true
    },
    // 页码跳转
    handleCurrentChange(page) {
      this.submitObj.page = page
      this.getReportListFn()
    },
    // 清空报告列表
    onEmptyDataFn() {
      const _this = this
      this.$confirm('是否清空数据?')
        .then((_) => {
          _this.emptyReportListFn()
        })
        .catch((_) => {})
    },
    // 清空报告列表
    async emptyReportListFn() {
      const data = {
        channel: this.channel
      }
      await this.emptyReportList(data)
    },
    // 跳转页面
    onSkipPageFn(url) {
      if (this.channel) {
        this.$router.push(`${url}?channel=${this.channel}`)
      } else {
        this.$router.push(`${url}`)
      }
    },
    // 监听打开退出弹窗
    onOutPageFn() {
      this.popUp = true
    },
    //当用户点击确定退出
    ensureFn() {
      history.go(-history.length + 1)
    },
    //当用户点击取消关闭退出应用的弹窗
    cancelfn(data) {
      this.popUp = data
    }
  }
}
</script>

<style lang="stylus" scoped>
.report-list-wrapper
  width 100%
  height auto
  .banner
    width 100%
    height auto
    img
      display block
      width 100%
      height auto
  .center-box
    position relative
    width 1544px
    height auto
    padding-top 30px
    margin auto
    ul
      width 100%
      height auto
      display flex
      flex-wrap wrap
      li
        width 368px
        height auto
        margin 0 24px 24px 0
        border-radius 13px
        transition all 0.2s
        cursor pointer
        box-shadow 0 6px 10px 0 rgba(95, 101, 105, 0.15)
        background #fff
        .top-box
          width 100%
          height auto
          padding 13px 17px 12px 20px
          border-bottom 1px solid #ECEAE6
          display flex
          justify-content space-between
          align-items center
          box-sizing border-box
          .left
            display flex
            align-items center
            img
              display block
              width 28px
              height 28px
              border-radius 50%
              overflow hidden
            span
              padding-left 8px
              font-size 14px
              color #34271D
          .right
            display flex
            align-items center
            span
              padding-right 5px
              font-size 14px
              color #34271D
            img
              display block
              width 14px
              height 14px
        .bottom-box
          width 100%
          height auto
          padding 20px
          display flex
          align-items center
          box-sizing border-box
          .left
            flex 1
            height auto
            .title
              font-size 18px
              line-height 18px
              font-weight 500
              color #34271D
            .date
              padding-top 18px
              font-size 14px
              line-height 14px
              color #7B756F
          .right
            position relative
            width 166px
            height 100px
            border-radius 8px
            overflow hidden
            img
              width 100%
              height 100%
              object-fit cover
            .right-fixed
              position absolute
              left 0
              top 0
              width 100%
              height 100%
              z-index 10
              border-radius 8px
              overflow hidden
              display flex
              justify-content center
              align-items center
              background-color rgba(0, 0, 0, 0.7)
              .num
                font-size 24px
                line-height 24px
                font-weight bold
                color #FFDD38
              span
                padding-right 2px
                font-size 14px
                color #FFDD38
      li:hover
        transform translateY(-2px)
        box-shadow 0 6px 10px 0 rgba(95, 101, 105, 0.15)
      li:nth-child(4n)
        margin-right 0
    .no-data
      width 100%
      height auto
      padding-top 10px
      text-align center
      font-size 24px
    .empty-btn
      position absolute
      right -80px
      top 30px
      width 50px
      height 50px
      border-radius 50%
      cursor pointer
      display flex
      align-items center
      justify-content center
      z-index 99
      box-shadow 0 6px 10px 0 rgba(95, 101, 105, 0.15)
      background-color #ffdd38
      img
        width 30px
        height 30px
  .pagination-container
    width 100%
    display flex
    justify-content center
  .return-btn
    position fixed
    left 3%
    top 50%
    width 50px
    height 50px
    cursor pointer
    img
      width 100%
      height 100%
</style>
